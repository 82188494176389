<template>
  <base-section
    id="k-d-a-solutions-card"
    :class="isOddNumber ? 'background-tech-list' : 'white'"
    top-inner-shadow
    space="80"
  >
    <v-container class="my-10 my-md-16">
      <v-row no-gutters :class="g_bLowerBr && isOddNumber ? 'col-reverse' : 'row-wrapper'">
        <v-col v-if="g_bHighestBr" lg="1" />
        <v-col v-else-if="g_bBaseBr" lg="1" />
        <v-col v-else-if="g_bHigherBr" lg="1" />
        <v-col
          v-if="!isOddNumber"
          cols="12"
          :lg="`${g_bHighestBr || g_bBaseBr ? 4 : 5}`"
          :md="`${g_bHighestBr || g_bBaseBr ? 4 : 5}`"
          style="flex-direction: column; display: flex; align-items: center"
        >
          <div class="img-container center" style="width: fit-content">
            <img
              :src="imgSolutions"
              :class="g_bLowestBr ? 'top' : g_bBaseBr ? 'right-base' : 'right'"
              style="object-fit: contain"
            />
          </div>
        </v-col>

        <v-col
          v-if="isOddNumber"
          cols="12"
          :lg="`${g_bHighestBr || g_bBaseBr ? 5 : 4}`"
          :md="`${g_bHighestBr || g_bBaseBr ? 5 : 4}`"
          class="mx-3"
          :style="stylesText"
        >
          <div>
            <div
              :class="`kda-ts-${g_bLowerBr ? 28 : 46}pt wt-extrabold mt-${
                g_bLowerBr ? 8 : 0
              } mt-md-${g_bLowerBr ? 8 : 0}`"
            >
              {{ strTitle }}
            </div>
            <component
              :is="strComponent"
              :class="`kda-ts-${g_bLowerBr ? 12 : 16}pt nunito wt-regular mt-4 mt-md-4`"
              v-html="htmlDescription"
            />
            <v-btn
              v-if="isButtonExists"
              color="main-r-400"
              class="text-capitalize white--text mt-4 mt-md-8"
              :x-large="$vuetify.breakpoint.mdAndUp"
            >
              {{ strButton }}
            </v-btn>
          </div>
        </v-col>
        <v-col v-if="g_bHighestBr" lg="1" />
        <v-col v-else-if="g_bBaseBr" lg="1" />
        <v-col v-else-if="g_bHigherBr" lg="1" />

        <v-col
          v-if="isOddNumber"
          cols="12"
          :lg="`${g_bHighestBr || g_bBaseBr ? 4 : 5}`"
          :md="`${g_bHighestBr || g_bBaseBr ? 4 : 5}`"
          style="flex-direction: column; display: flex; align-items: center"
        >
          <div class="img-container center">
            <img
              :src="imgSolutions"
              :class="g_bLowestBr ? 'top' : g_bBaseBr ? 'right-base' : 'right'"
              style="object-fit: contain"
            />
          </div>
        </v-col>
        <v-col
          v-if="!isOddNumber"
          cols="12"
          :lg="`${g_bHighestBr || g_bBaseBr ? 5 : 4}`"
          :md="`${g_bHighestBr || g_bBaseBr ? 5 : 4}`"
          class="mx-0"
          :style="stylesText"
        >
          <div>
            <div
              :class="`kda-ts-${g_bLowerBr ? 28 : 46}pt wt-extrabold mt-${
                g_bLowerBr ? 8 : 0
              } mt-md-${g_bLowerBr ? 8 : 0}`"
            >
              {{ strTitle }}
            </div>
            <component
              :is="strComponent"
              :class="`kda-ts-${g_bLowerBr ? 12 : 16}pt nunito wt-regular mt-4 mt-md-4`"
              v-html="htmlDescription"
            />
            <v-btn
              v-if="isButtonExists"
              color="main-r-400"
              class="text-capitalize white--text mt-4 mt-md-8"
              :x-large="$vuetify.breakpoint.mdAndUp"
            >
              {{ strButton }}
            </v-btn>
          </div>
        </v-col>

        <v-col v-if="g_bHighestBr" lg="1" />
        <v-col v-else-if="g_bBaseBr" lg="1" />
        <v-col v-else-if="g_bHigherBr" lg="1" />
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDASolutionsCard',

  mixins: [BaseBreakpoint],

  props: {
    strTitle: {
      type: String,
      default: ''
    },
    strComponent: {
      type: String,
      default: ''
    },
    strButton: {
      type: String,
      default: ''
    },
    htmlDescription: {
      type: String,
      default: ''
    },
    numIndex: {
      type: Number,
      default: 0
    },
    imgSolutions: {
      type: String,
      default: ''
    },
    isButtonExists: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isOddNumber() {
      return this.numIndex % 2 === 1;
    },
    stylesText() {
      return {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `${
          this.$vuetify.breakpoint.smAndDown && this.isOddNumber
            ? '0px 20px 0px 0px'
            : this.$vuetify.breakpoint.smAndDown && !this.isOddNumber
            ? '0px 10px'
            : '0px'
        }`
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.col-reverse {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
}

.background-tech-list {
  background-color: #fbfbfb;
}

.cursor-pointer {
  cursor: pointer;
}

.img-container {
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  img {
    &.right {
      height: 460px;
      width: 460px;
    }

    &.top {
      left: 0;
      top: 0;
      width: 100%;
      position: relative;
      padding: 0px 10px;
    }

    &.right-base {
      height: fit-content;
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
